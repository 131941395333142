.home {
  width: 100%;
  height: 1375px;
  scroll-behavior: smooth;
}
.home-title {
  padding-top: 2px;
  height: 793px;
}
.home-presentation {
  border-radius: 0px;
  height: 600px;
  background-color: #fdfd96;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-presentation-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 150px;
  border: solid 3px black;
}
.home-presentation-cv {
  color: black;
  font-size: 20px;
  margin-top: 30px;
  font-family: "Syne", sans-serif;
  text-decoration: none;
  transition: all 500ms;
  padding-bottom: 30px;
}
.home-presentation-cv:hover {
  transform: translateY(-10px);
  padding-bottom: 10px;
}

.border-underline-cv::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  height: 4px;
  background-color: black;
  border-radius: 10px;
  transform-origin: left;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.border-underline-cv:hover::before {
  transform: scaleX(1);
}

@media all and (max-width: 1200px) {
  .home-presentation {
    flex-direction: column-reverse;
    align-items: center;
    gap: 25px;
  }
  .home-presentation-div {
    width: 250px;
    margin: 0;
  }
  .home-presentation-cv {
    font-size: 17px;
  }
  .home-presentation-cards {
    text-align: center;
    gap: 10px;
  }
  .home-presentation-cards-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media all and (max-width: 1000px) {
  .home {
    height: 1400px;
  }
}
@media all and (max-width: 752px) {
  .home {
    height: 1500px;
  }
  .home-presentation {
    height: 700px;
  }
}
@media all and (max-width: 700px) {
  .home {
    height: 1550px;
  }
  .home-presentation {
    height: 700px;
  }
  .home-presentation-cards {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 25px;
  }
}
@media all and (max-width: 540px) {
  .home {
    height: 1600px;
  }
  .home-presentation {
    height: 800px;
  }
}
@media all and (max-width: 450px) {
  .home {
    height: 1130px;
  }
}/*# sourceMappingURL=Home.css.map */