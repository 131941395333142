@media all and (max-width: 1921px) and (min-width: 1901px) {
  @keyframes color {
    from {
      color: white;
    }
    to {
      color: yellow;
    }
  }
  @keyframes capital-letter-opacity {
    from {
      opacity: 0;
      margin-right: 10px;
    }
  }
  @keyframes margin-letter-a {
    from {
      margin-right: 205px;
    }
    to {
      margin-right: 1070px;
    }
  }
  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes first-o-slider-begin {
    from {
      opacity: 0;
      left: 813px;
    }
  }
  @keyframes first-o-slide {
    from {
      left: 828px;
    }
    to {
      left: 725px;
    }
  }
  @keyframes second-o-slider-begin {
    from {
      opacity: 0;
      top: 160px;
    }
  }
  @keyframes second-o-slide {
    from {
      left: 920px;
    }
    to {
      left: 995px;
    }
  }
  @keyframes third-o-slider-begin {
    from {
      opacity: 0;
      left: 1025px;
    }
  }
  @keyframes third-o-slide {
    from {
      left: 1010px;
    }
    to {
      left: 1160px;
    }
  }
  .animated-title {
    position: relative;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .responsive-animated-title {
    display: none;
  }
  .surname {
    font-family: "Rubik", sans-serif;
    font-size: 120px;
    color: white;
    margin: 0px;
    margin-left: 25px;
  }
  .surname-capital-letter {
    margin-right: -10px;
    animation: 1200ms ease-in-out capital-letter-opacity;
    color: white;
  }
  .surname-natole {
    position: absolute;
    left: 480px;
    opacity: 0;
  }
  .surname-natole-animated {
    position: absolute;
    left: 480px;
    opacity: 0;
    animation: opacity 1200ms ease-in-out;
    animation-delay: 700ms;
    animation-fill-mode: both;
  }
  .surname-natole-stop {
    position: absolute;
    left: 480px;
    opacity: 1;
  }
  .surname-eni {
    position: absolute;
    right: 625px;
    top: 0px;
    opacity: 0;
  }
  .surname-eni-animated {
    animation: opacity 1500ms ease-in-out;
    animation-delay: 800ms;
    animation-fill-mode: both;
  }
  .surname-eni-stop {
    position: absolute;
    right: 625px;
    top: 0px;
    opacity: 1;
  }
  .surname-mal {
    position: absolute;
    right: 368px;
    top: 0px;
    opacity: 0;
  }
  .surname-mal-animated {
    animation: opacity 1500ms ease-in-out;
    animation-delay: 900ms;
    animation-fill-mode: both;
  }
  .surname-mal-stop {
    position: absolute;
    right: 368px;
    top: 0px;
    opacity: 1;
  }
  .surname-title-border {
    position: absolute;
    right: 330px;
    top: 25px;
    height: 110px;
    width: 270px;
    border-right: solid 15px yellow;
    border-bottom: solid 15px yellow;
    opacity: 0;
  }
  .letter-a {
    margin-right: 205px;
  }
  .letter-a-slide {
    color: yellow;
    transition: color 600ms;
    animation: margin-letter-a 1250ms cubic-bezier(0.1, 1.04, 0.9, 1);
    animation-delay: 100ms;
    animation-fill-mode: both;
  }
  .letter-a-stop {
    margin-right: 1070px;
    color: yellow;
  }
  .letter-d {
    position: absolute;
    right: 835px;
    top: 0px;
  }
  .letter-d-yellow {
    color: yellow;
    transition: color 600ms ease-in-out;
  }
  .letter-d-stop {
    position: absolute;
    right: 825px;
    top: 0px;
    color: yellow;
  }
  .subtitle {
    padding-left: 30px;
    width: 300px;
    height: 100px;
    font-family: "Syne", sans-serif;
    font-size: 90px;
    font-weight: 600;
    color: white;
    margin: 0px;
    opacity: 1;
  }
  .subtitle-capital-letter {
    margin-right: -10px;
    animation: 1200ms ease-in-out capital-letter-opacity;
    color: white;
  }
  .subtitle-border {
    border-bottom: solid 15px yellow;
    opacity: 1;
  }
  .subtitle-letter-p {
    position: absolute;
    left: 657px;
    opacity: 0;
  }
  .subtitle-letter-p-animated {
    animation: opacity 1500ms ease-in-out;
    animation-delay: 1200ms;
    animation-fill-mode: both;
  }
  .subtitle-letter-p-stop {
    position: absolute;
    left: 657px;
    opacity: 1;
  }
  .subtitle-rtf {
    position: absolute;
    left: 805px;
    opacity: 0;
  }
  .subtitle-rtf-animated {
    animation: opacity 1500ms ease-in-out;
    animation-delay: 1200ms;
    animation-fill-mode: both;
  }
  .subtitle-rtf-stop {
    position: absolute;
    left: 805px;
    opacity: 1;
  }
  .subtitle-li {
    position: absolute;
    left: 1075px;
    opacity: 0;
  }
  .subtitle-li-animated {
    animation: opacity 1500ms ease-in-out;
    animation-delay: 1200ms;
    animation-fill-mode: both;
  }
  .subtitle-li-stop {
    position: absolute;
    left: 1075px;
    opacity: 1;
  }
  .first-o {
    position: absolute;
    left: 828px;
    animation: first-o-slider-begin 1500ms both;
    animation-delay: 100ms;
  }
  .first-o-slide {
    color: yellow;
    transition: color 600ms ease-in-out;
    animation: first-o-slide 1800ms cubic-bezier(0.1, 1.04, 0.9, 1);
    animation-fill-mode: both;
  }
  .first-o-stop {
    position: absolute;
    left: 725px;
    color: yellow;
  }
  .second-o {
    position: absolute;
    left: 920px;
    top: 142px;
    animation: second-o-slider-begin 1500ms both;
    animation-delay: 100ms;
  }
  .second-o-slide {
    color: yellow;
    transition: color 600ms ease-in-out;
    animation: second-o-slide 1800ms cubic-bezier(0.1, 1.04, 0.9, 1);
    animation-fill-mode: both;
  }
  .second-o-stop {
    position: absolute;
    left: 995px;
    top: 142px;
    color: yellow;
  }
  .third-o {
    position: absolute;
    left: 1010px;
    animation: third-o-slider-begin 1500ms both;
    animation-delay: 100ms;
  }
  .third-o-slide {
    color: yellow;
    transition: color 600ms ease-in-out;
    animation: third-o-slide 1800ms cubic-bezier(0.1, 1.04, 0.9, 1);
    animation-fill-mode: both;
  }
  .third-o-stop {
    position: absolute;
    left: 1160px;
    top: 142px;
    color: yellow;
  }
}
@media all and (max-width: 1900px) {
  .animated-title {
    display: none;
  }
  .yellow-color {
    color: yellow;
  }
  .responsive-animated-title {
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }
  .responsive-surname {
    font-family: "Rubik", sans-serif;
    font-size: 120px;
    margin: 0;
  }
  .responsive-subtitle {
    font-family: "Syne", sans-serif;
    font-size: 90px;
    font-weight: 600;
    margin: 0;
  }
}
@media all and (max-width: 1200px) {
  .responsive-surname {
    font-size: 100px;
  }
  .responsive-subtitle {
    font-size: 70px;
  }
}
@media all and (max-width: 1000px) {
  .responsive-animated-title {
    margin-top: 100px;
  }
  .responsive-surname {
    font-size: 80px;
  }
  .responsive-subtitle {
    font-size: 50px;
  }
}
@media all and (max-width: 790px) {
  .responsive-animated-title {
    margin-top: 100px;
    text-align: center;
  }
}
@media all and (max-width: 450px) {
  .responsive-animated-title {
    margin-top: 50px;
  }
  .responsive-surname {
    font-size: 50px;
  }
  .responsive-subtitle {
    font-size: 20px;
  }
}/*# sourceMappingURL=AnimatedTitle.css.map */