.bg-container {
  position: fixed;
  z-index: -1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bg-container-video {
  width: 100%;
  height: 1000px;
  -o-object-fit: cover;
     object-fit: cover;
  filter: invert(1);
}/*# sourceMappingURL=BgVideo.css.map */