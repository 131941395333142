.display-none {
  display: none;
}

@media all and (max-width: 1920px) and (min-width: 1901px) {
  .pic-div {
    width: 100%;
    height: 100px;
    margin-top: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(180px);
  }
  .pic-div-slider {
    width: 100%;
    height: 100px;
    margin-top: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0px);
    transition: transform 1500ms cubic-bezier(0.1, 1.04, 0.9, 1);
    transition-delay: 1500ms;
  }
  .pic-div-game {
    width: 40px;
    height: 40px;
    margin-left: 35px;
    margin-bottom: 5px;
    filter: invert(1);
    transition: transform 500ms;
  }
  .pic-div-game:hover {
    transform: translateY(-10px);
    cursor: pointer;
  }
  .pic-div-arrow {
    width: 100px;
    height: 40px;
    margin-top: 35px;
    filter: invert(1);
    transition: transform 500ms;
  }
  .pic-div-arrow:hover {
    transform: translateY(-10px);
    cursor: pointer;
  }
  .pic-div-mic {
    width: 40px;
    height: 40px;
    margin-right: 25px;
    margin-bottom: 15px;
    filter: invert(1);
    transition: transform 500ms;
  }
  .pic-div-mic:hover {
    transform: translateY(-10px);
    cursor: pointer;
  }
}
@media all and (max-width: 1900px) {
  .pic-div {
    width: 100%;
    height: 100px;
    margin-top: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(180px);
  }
  .pic-div-slider {
    width: 100%;
    height: 100px;
    margin-top: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0px);
    transition: transform 1500ms cubic-bezier(0.1, 1.04, 0.9, 1);
    transition-delay: 1500ms;
  }
  .pic-div-game {
    width: 40px;
    height: 40px;
    margin-left: 35px;
    margin-bottom: 5px;
    filter: invert(1);
    transition: transform 500ms;
  }
  .pic-div-game:hover {
    transform: translateY(-10px);
    cursor: pointer;
  }
  .pic-div-arrow {
    width: 100px;
    height: 40px;
    margin-top: 35px;
    filter: invert(1);
    transition: transform 500ms;
  }
  .pic-div-arrow:hover {
    transform: translateY(-10px);
    cursor: pointer;
  }
  .pic-div-mic {
    width: 40px;
    height: 40px;
    margin-right: 25px;
    margin-bottom: 15px;
    filter: invert(1);
    transition: transform 500ms;
  }
  .pic-div-mic:hover {
    transform: translateY(-10px);
    cursor: pointer;
  }
}
@media all and (max-width: 1000px) {
  .pic-div-slider {
    margin-top: 150px;
  }
}
@media all and (max-width: 450px) {
  .pic-div-slider {
    margin-top: 50px;
  }
  .pic-div-arrow {
    width: 70px;
    height: 30px;
  }
}/*# sourceMappingURL=ChangeTheme.css.map */