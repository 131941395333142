.displayworks {
    margin: 80px;
    max-width: 800px;
    height: auto;
    border-radius: 15px;
    transition: all 400ms ease-in-out;
    background-color: #fdfd96;
    padding: 9px 20px;
    &:hover &-img {
        transform: scale(1.05);
        filter: grayscale(0);
    }
    &-img {
        width: 100%;
        height: 450px;
        object-fit: cover;
        object-position: 0% 0%;
        border-radius: 15px;
        transition: all 400ms ease-in-out;
        box-shadow: 2px 2px 10px black;
        filter: grayscale(1);
    }
}

@media all and (max-width: 750px) {
    .displayworks {
        height: 250px;
        margin: 50px 30px;
        &-img {
            height: 250px;
        }
    }
}
