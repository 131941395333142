.presentation-description {
  width: 550px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  padding: 15px;
  border: solid 3px black;
}
.presentation-description-text {
  color: black;
  font-family: "Syne", sans-serif;
  font-size: 25px;
}

@media all and (max-width: 1200px) {
  .presentation-description {
    width: 80%;
    text-align: center;
  }
  .presentation-description-text {
    font-size: 20px;
  }
}
@media all and (max-width: 450px) {
  .presentation-description-text {
    font-size: 15px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}/*# sourceMappingURL=PresentationText.css.map */