.home {
    width: 100%;
    height: 1375px;
    scroll-behavior: smooth;
    &-title {
        padding-top: 2px;
        height: 793px;
        // position: relative;
    }
    &-presentation {
        border-radius: 0px;
        height: 600px;
        background-color: #fdfd96;
        display: flex;
        align-items: center;
        justify-content: center;
        // &-cards {
        //     display: grid;
        //     grid-template-columns: repeat(4, 1fr);
        //     gap: 25px;
        // }
        &-div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 150px;
            border: solid 3px black;
        }
        &-cv {
            color: black;
            font-size: 20px;
            margin-top: 30px;
            font-family: 'Syne', sans-serif;
            text-decoration: none;
            transition: all 500ms;
            padding-bottom: 30px;
            &:hover {
                transform: translateY(-10px);
                padding-bottom: 10px;
            }
        }
    }
}

.border-underline-cv::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 4px;
    background-color: black;
    border-radius: 10px;
    transform-origin: left;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}

.border-underline-cv:hover::before {
    transform: scaleX(1);
}

@media all and (max-width: 1200px) {
    .home {
        &-presentation {
            flex-direction: column-reverse;
            align-items: center;
            gap: 25px;
            &-div {
                width: 250px;
                margin: 0;
            }
            &-cv {
                font-size: 17px;
            }
            &-cards {
                text-align: center;
                gap: 10px;
                &-div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }
}

@media all and (max-width: 1000px) {
    .home {
        height: 1400px;
    }
}

@media all and (max-width: 752px) {
    .home {
        height: 1500px;
        &-presentation {
            height: 700px;
        }
    }
}

@media all and (max-width: 700px) {
    .home {
        height: 1550px;
        &-presentation {
            height: 700px;
            &-cards {
                grid-template-columns: repeat(2, 1fr);
                padding-bottom: 25px;
            }
        }
    }
}

@media all and (max-width: 540px) {
    .home {
        height: 1600px;
        &-presentation {
            height: 800px;
        }
    }
}

@media all and (max-width: 450px) {
    .home {
        height: 1130px;
    }
}
