.footer {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-copyright {
        color: white;
        font-family: 'Rubik', sans-serif;
        font-size: 15px;
    }
    &-img {
        width: 35px;
        height: 35px;
        margin-right: 15px;
        margin-left: 15px;
        border-radius: 10px;
    }
}

.gmail-logo {
    filter: invert(1);
}
