.portfolio {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-title {
        margin-top: 50px;
        color: #fdfd96;
        font-size: 100px;
        font-family: 'Rubrik', sans-serif;
    }
    &-sectioncards {
        max-width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        justify-content: center;
        border-radius: 10px;
    }
}

@media all and (max-width: 1440px) {
    .portfolio-sectioncards {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media all and (max-width: 1000px) {
    .portfolio {
        &-title {
            font-size: 75px;
        }
    }
}
