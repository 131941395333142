.worksection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0px 50px;
}
.worksection-left {
  width: 100%;
}
.worksection-left-img {
  width: 90%;
  height: 850px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 0% 50%;
     object-position: 0% 50%;
  border-radius: 10px;
}
.worksection-right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 35px;
  border-radius: 20px;
  background-color: #fdfd96;
}
.worksection-right-title {
  border-radius: 10px;
  padding: 15px;
  color: black;
  font-family: "Rubrik", sans-serif;
  font-size: 50px;
}
.worksection-right-date, .worksection-right-lien, .worksection-right-client, .worksection-right-tags, .worksection-right-difficults {
  width: auto;
  border-radius: 10px;
  padding: 15px;
  font-family: "Rubrik", sans-serif;
  font-size: 22px;
}
.worksection-right-tag {
  display: flex;
  gap: 40px;
}
.worksection-right-difficult {
  display: flex;
  gap: 40px;
}

@media all and (max-width: 1150px) {
  .worksection {
    grid-template-columns: repeat(1, 1fr);
  }
  .worksection-left {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
  .worksection-left-img {
    width: 100%;
    height: 450px;
  }
}
@media all and (max-width: 650px) {
  .worksection {
    padding: 0px 25px;
  }
  .worksection-right {
    align-items: center;
    padding-left: 0px;
  }
  .worksection-right-title {
    font-size: 35px;
  }
  .worksection-right-date, .worksection-right-lien, .worksection-right-client, .worksection-right-tags, .worksection-right-difficults {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .worksection-right-difficult, .worksection-right-tag {
    gap: 30px;
    padding: 0;
  }
}
@media all and (max-width: 600px) {
  .worksection {
    padding: 0px 25px;
  }
  .worksection-right-tag, .worksection-right-difficult {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}/*# sourceMappingURL=Work.css.map */