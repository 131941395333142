.presentation-pic {
  width: 300px;
  height: 300px;
}
.presentation-pic-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0px;
}

@media all and (max-width: 1200px) {
  .presentation-pic {
    width: 250px;
    height: 250px;
  }
}/*# sourceMappingURL=PresentationImage.css.map */