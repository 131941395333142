.worksection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0px 50px;
    &-left {
        width: 100%;
        &-img {
            width: 90%;
            height: 850px;
            object-fit: cover;
            object-position: 0% 50%;
            border-radius: 10px;
        }
    }
    &-right {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 35px;
        border-radius: 20px;
        background-color: #fdfd96;
        &-title {
            border-radius: 10px;
            padding: 15px;
            color: black;
            font-family: 'Rubrik', sans-serif;
            font-size: 50px;
        }
        &-date,
        &-lien,
        &-client,
        &-tags,
        &-difficults {
            width: auto;
            border-radius: 10px;
            padding: 15px;
            font-family: 'Rubrik', sans-serif;
            font-size: 22px;
        }
        &-tag {
            display: flex;
            gap: 40px;
        }
        &-difficult {
            display: flex;
            gap: 40px;
        }
    }
}

@media all and (max-width: 1150px) {
    .worksection {
        grid-template-columns: repeat(1, 1fr);
        &-left {
            display: flex;
            justify-content: center;
            margin-bottom: 50px;
            &-img {
                width: 100%;
                height: 450px;
            }
        }
    }
}

@media all and (max-width: 650px) {
    .worksection {
        padding: 0px 25px;
        &-right {
            align-items: center;
            padding-left: 0px;
            &-title {
                font-size: 35px;
            }
            &-date,
            &-lien,
            &-client,
            &-tags,
            &-difficults {
                font-size: 18px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
            &-difficult,
            &-tag {
                gap: 30px;
                padding: 0;
            }
        }
    }
}

@media all and (max-width: 600px) {
    .worksection {
        padding: 0px 25px;
        &-right {
            &-tag,
            &-difficult {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }
    }
}
