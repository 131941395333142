.contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-title {
  color: #fdfd96;
  font-size: 100px;
  font-family: "Rubrik", sans-serif;
}
.contact-section {
  max-width: 75%;
  height: auto;
  padding: 50px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
}
.contact-card {
  padding: 20px 0px;
  min-width: 100px;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: solid 2px white;
  box-shadow: 1px 1px 10px black;
  background-color: #fdfd96;
  transition: all 250ms;
  text-decoration: none;
}
.contact-card:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.contact-card-img {
  width: 70px;
  height: 70px;
  padding-left: 20px;
  margin-right: 50px;
}
.contact-card-text {
  color: black;
  font-family: "Rubrik", sans-serif;
  font-size: 25px;
}

@media all and (max-width: 1440px) {
  .contact-section {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media all and (max-width: 780px) {
  .contact-title {
    font-size: 75px;
  }
  .contact-card-img {
    width: 40px;
    height: 40px;
  }
  .contact-card-text {
    font-size: 20px;
    text-wrap: wrap;
  }
}
@media all and (max-width: 640px) {
  .display-none-contact {
    display: none;
  }
  .contact-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contact-card {
    width: 90%;
  }
  .contact-card-text {
    margin-left: 30px;
  }
}/*# sourceMappingURL=Contact.css.map */