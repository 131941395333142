.header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 125px;
}

.navbar {
    display: flex;
    justify-content: space-between;
    width: 600px;
    height: 50px;
    transform: translateY(-100px);
    &-slider {
        display: flex;
        justify-content: space-between;
        width: 600px;
        height: 50px;
        transform: translateY(0px);
        transition: transform 1500ms cubic-bezier(0.1, 1.04, 0.9, 1);
        transition-delay: 1500ms;
    }
    &-stop {
        display: flex;
        justify-content: space-between;
        width: 600px;
        height: 50px;
    }
}

.button {
    position: relative;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-family: 'Syne', sans-serif;
    font-size: 23px;
    font-weight: 800;
    color: white;
    transition: transform 500ms;
    &:hover {
        height: 50px;
        transform: translateY(-10px);
    }
}

.border-underline::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 4px;
    background-color: yellow;
    border-radius: 10px;
    transform-origin: left;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}

.border-underline:hover::before {
    transform: scaleX(1);
    background-color: yellow;
}

@media all and (max-width: 750px) {
    .button {
        font-size: 20px;
        margin: 0px 20px;
    }
}

@media all and (max-width: 520px) {
    .button {
        font-size: 15px;
        margin: 0px 15px;
    }
}

@media all and (max-width: 400px) {
    .navbar-stop {
        justify-content: space-around;
        width: 100%;
    }

    .button {
        font-size: 12px;
    }
}
